import { title } from 'process';
import React, { useContext, useRef, useState } from 'react';
import { BsFillCheckCircleFill, BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';


import GtinResults from '../../../../components/GtinResults';
import ExportDetail from '../../../../components/ExportDetail';
import Warning from '../../../../components/Warning';
import { LoginContext } from '../../../../contexts/login_context';
import { getProduct } from '../../../../services/getProduct';
import { processGTINData } from '../../../../services/gtin_process';
import { dismissToast, showError, showWait } from '../../../../services/toast';
import { ModalContestation } from '../../../../components/ModalContestation';
import { useSelector } from '../../../../native-state.dev';



const SearchOneGTIN = () => {
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);
  const no_image_url = '/jam.png';
  const [view, setView] = useState({ error: false, content: false });
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { jwt } = useContext(LoginContext);
  const [code, setCode] = useState("");
  const unknown_text = 'Desconhecido';
  const [isFocused, setFocused] = useState(false)
  const myRef: any = useRef();

  let navigate = useNavigate();
  let location = useLocation();
 
  let productData =
  {
    gtin: '',
    vbg: {
      brandName: unknown_text,
      language: unknown_text,
      flag: unknown_text,
      country: '',
      productdescription: unknown_text,
      productImage: no_image_url,
      countrysalescode: {
        alpha: unknown_text,
        alpha1: unknown_text,
        numeric: unknown_text,
      },
      gpccategorycode: unknown_text,
      gtin: unknown_text,
      isComplete: '',
      licenseMessage: unknown_text,
      licenseName: unknown_text,
      gtinRecordStatus: '',
      netContent: {
        unitCode: '',
        value: unknown_text
      },
      hasError: {
        error: 3,
        showpic: false,
        title: unknown_text,
        message: unknown_text,
        message_type: unknown_text
      },
      containsProduct: true
    },
    cnp: {
      gtin: unknown_text,
      gtinOrigin: unknown_text,
      quantityGtinOrigin: unknown_text,
      brandName: unknown_text,
      flag: unknown_text,
      country: '',
      productDescription: unknown_text,
      productImage: no_image_url,
      additionalProductImage: [],
      classification: unknown_text,
      netContent: {
        measurementUnitCode: '',
        value: unknown_text
      },
      grossWeight: {
        measurementUnitCode: '',
        value: unknown_text
      },
      ncm: unknown_text,
      cest: unknown_text,
      syncInformationCCG: unknown_text,
      hasError: {
        error: 0,
        showpic: false,
        title: unknown_text,
        message: unknown_text
      }
    },
    gepir: {
      text: unknown_text,
      error: 1,
    },
    validations: {
      descricaoAdicional: unknown_text,
      idiomaDescAdicional: unknown_text,
      idiomaImagemAdicional: unknown_text,
      idiomaMarcaAdicional: unknown_text,
      imagemAdicional: unknown_text,
      marcaAdicional: unknown_text,
      tipoGtin: unknown_text,
    },
    messages: {
      cnp: {
        error: false,
        message: unknown_text,
        title: unknown_text
      },
      gepir: {
        error: false,
        message: unknown_text,
        title: unknown_text,
        visible: true
      },
      vbg: {
        error: false,
        message: unknown_text,
        title: unknown_text
      },
      geral: {
        error: false,
        message: unknown_text,
        title: unknown_text
      },
    }
  }

  const [data, setData] = useState(productData);
  const [dataPrepared, setdataPrepared] = useState<any[]>([]);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send()
  }
  async function send() {
    searchCode();

  }
  const checkDigit = (gtin: string) => {
    let position_number: number = 17;
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;

    

    if (gtin !== '') {
      //utilizado 14 porque é o número máximo de carateres que a GS1 trabalha
      if (gtin.length <= 14) {
        let position_value = [ 3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3]; //matriz base de cálculo (https://www.gs1.org/services/how-calculate-check-digit-manually)
        let gtin_total = 0;
        let gtin_positions = gtin.split("").slice(0, -1); 
        let add_zero = position_number - gtin.length + 1; 
        let multiple_10 = 0;

        if (add_zero < position_number) {
          for (let i = 0; i < add_zero; i++) {
            gtin_positions.unshift('0');
          }
        }

        for (let i = 0; i < position_number; i++) {
          if(position_value[i])
            gtin_total = gtin_total + (position_value[i] * parseInt(gtin_positions[i]));
        }

        if (gtin_total === Math.ceil((gtin_total) / 10) * 10) {
          multiple_10 = gtin_total;
        }
        else {
          multiple_10 = Math.ceil((gtin_total + 1) / 10) * 10;
        }

        let check_digit = multiple_10 - gtin_total

        if (check_digit !== parseInt(gtin.substring(gtin.length - 1))) {
          error = true;
          titleMessage = `Dígito verificador incorreto. Você quis dizer ${gtin.slice(0, -1)}${check_digit}?`;
          message = '';
        }

      }
      else {
        error = true;
        titleMessage = '';
        message = `O número do código de barras (GTIN) informado ${gtin} não pode ser maior que 14 dígitos.`;
      }
    }
    else {
      error = true;
      titleMessage = `Número do código de barras (GTIN) não foi encontrado.`;
      message = '';
    }

    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const validateGtin = (gtin: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
    setView({ error: false, content: false });


    if (!/^[0-9]+$/.test(gtin)) {
      error = true;
      titleMessage = 'O número do código de barras (GTIN) deve conter apenas caracteres numéricos.'
      message = `Por favor, verifique se há erros de digitação.`;
    }
    else if (gtin.length < 8) {
      error = true;
      titleMessage = 'O número do código de barras (GTIN) informado é curto, podem ter 8, 12, 13 ou 14 dígitos.'
      message = `Por favor, verifique se há erros de digitação.`;
    }
    else if (gtin.length === 9 || gtin.length === 10 || gtin.length === 11) {
      error = true;
      titleMessage = 'O número informado não é um código de barras (GTIN) válido.'
      message = `Por favor, verifique erros de digitação e contate a GS1 Brasil se precisar de ajuda.`;
    }
    else if (gtin.substring(0, 3) === '000') {
      error = true;
      titleMessage = 'O numero informado não é um código de barras (GTIN) válido.';
      message = `Por favor, verifique se há erros de digitação.`;
    }
    else {
      let verify_digit = checkDigit(gtin);
      error = verify_digit.error;
      titleMessage = verify_digit.titleMessage
      message = verify_digit.message;
    }


    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const searchCode = async () => {

    let valid = validateGtin(code);

    if (!valid.error) {
      let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');
      setIsDisabled(true);

      const response: any = await getProduct([code], jwt);
      dismissToast(toastId);
      setIsDisabled(false);

      if (response.status === 200) {
        let auxDatePrepared: any[] = [];

        setdataPrepared(response.data[0]);

        if (response.data != null && response.data.length > 0) {
          setView({ error: false, content: true });


          if (myRef && myRef.current)
            myRef.current.scrollIntoView();

          productData = processGTINData(response.data[0]);
          setData(productData);
          setState({searchDone:true});


        }
        else {
          setView({ error: true, content: false });
          setErrorTitle(`O número do código de barras (GTIN) não foi encontrado.`);
        }

      }
      else {

        if (response.code === 401) {
          navigate("/" + location.search);
        }

        showError(response.msg);
        setView({ error: false, content: false });
      }
    }
    else {
      setView({ error: true, content: false });
      setErrorTitle(valid.titleMessage);
      setErrorMessage(valid.message);
    }


  }


  return (

    <>


      <div className='bg-color-ui-1  ' >

        <div className='container'>

          <div className='row customWidth bg-color-white  px-4 rounded-lg' >

            <div className='col-12 col-md-12 mb-5' >
            <form className='customForm' onSubmit={onFormSubmit}>
                  <div className='custom-container-input' style={{display:'flex'}}>
                        <div className='custom-border5GTIN'
                        >
                            <label
                            
                            className='container_my_input_custom custom-height-container-input'>
                            <BsSearch />
                              <input
                              id='inputIdGTIN'
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              placeholder="Digite um número de código de barra (GTIN)"
                              onChange={({ target }) => setCode(target.value)}
                              value={code}
                              className="especial_input"
                              style={{outline:'none', border: 'none'}}
                              disabled={isDisabled}
                              />

                            </label>
                        </div>
                        <div>
                          <button
                              type="submit"
                              className="custom-button-especial"
                              disabled={isDisabled}
                          >
                              Buscar
                          </button>
                        </div>
                    </div>
                </form>    
                <div style={{ marginTop: "5px" }}>
                            <span className="customSpan">
                              Exemplo:{" "}
                              <a href='javascript:;'>
                                <span
                                  onClick={() => setCode("9506000140445")}
                                  className="link"
                                >
                                    9506000140445
                                </span>
                              </a>
                            </span>
                </div>
            </div>


            <div ref={myRef}></div>



          </div>

        </div>

      </div>
      <div className='col-12' style={{ width: '100%', overflow: 'hidden', padding: '0' }}>

        {view.error && (

          <div className='row'>

            <Warning
              type='error'
              showpick={false}
              title={errorTitle}
              text={errorMessage}
            ></Warning>

          </div>

        )}

        {view.content && (

          <>
            
            <GtinResults data={data} code={[code]}  />


          </>

        )}
      </div>

      {view.content && (<ExportDetail dataPrepared={dataPrepared} itemType={'GTIN'} />)}
      
    </>

  );


};

export default SearchOneGTIN;



