import React, { useState, useRef } from "react";
import "./style.css";
import CompanyDetailGln from "./CompanyDetailGln";
import ProductDetailGln from "./ProductDetailGln";
import NewWarning from "../newWarning";

const GlnResults = ({ data }: { data: any }) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };

  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  const windowSize = useRef([window.innerWidth, window.innerHeight]);


  return (

    <div className="Tabs">
      <div className=' ' style={{ width: '100%' }}>
       

      {(data.retornoMsg.showMSG) && (
          <NewWarning
            type={data.retornoMsg.vbg_status}
            showpick={data.retornoMsg.vbg_show_pic}
            title={data.retornoMsg.vbg_title}
            text={data.retornoMsg.vbg_error_message}

          ></NewWarning>
        )}

      </div>
      <>
      
      {Object.keys(data.companyData).length !== 0 &&(
        <>
        {data.retornoMsg.show_content && (
          <div>
            <ul className="nav">
              {
                (!!data.productData && data.productData.length >0) ?
                  <>
                    <li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>{windowSize.current[0] > 600 ? 'Informações do GLN' : 'GLN'}</li>
                    <li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>{windowSize.current[0] > 600 ? 'Informações da Empresa' : 'Empresa'}</li>
                  </> :
                  <>
                    <li className="active" >{windowSize.current[0] > 600 ? 'Informações da Empresa' : 'Empresa'}</li>
                  </>
              }

            </ul>




            <div className="outlet">

              {
                (!!data.productData && data.productData.length >0) ?
                  <>
                    {
                      activeTab === "tab1" ?
                        <><ProductDetailGln data={data} /> </>
                        :
                        <><CompanyDetailGln data={data} /> </>
                    }
                  </> :
                  <>
                    <CompanyDetailGln data={data} />
                  </>
              }



            </div>
          </div>
        )}
        </>
      )}
        

      </>

    </div>
  );
};
export default GlnResults;
