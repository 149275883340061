export function getConfig() {

    return {
	    API_URL : "https://verifiedbygs1middleware.gs1br.org",
        // API_URL : "https://dataout-back-homologacao.azurewebsites.net",
        // API_URL : "http://localhost:5000", //LOCAL DEV
        // RECAPTCHA_SITE: "6LddlDshAAAAAPIL6yI1i5ZruCJsMBiv3Gpz-FJQ",     // LOCAL DEV
        //RECAPTCHA_SITE: "6Ldjw0AhAAAAALCmzAWorqPR5bP_MvvMKMJutg-N", // Luxfacta QA
        RECAPTCHA_SITE: "6LezuT8hAAAAAOO3q-PCWfHK8zljWRJUiBabrhAQ", // Producao
        //GOOGLE_TAG_MANAGER: "GTM-TVHCJ39" // Producao
        //GOOGLE_TAG_MANAGER: "GTM-PWVSD9Q", // Luxfacta Teste/QA
        // RECAPTCHA_SITE: "6LdMN14kAAAAAMHRfYvNjOxs5Ml-RkNzMyRW1mdO", // Homologacao
		//RECAPTCHA_SITE: "6LezuT8hAAAAAOO3q-PCWfHK8zljWRJUiBabrhAQ", // Producao
        GOOGLE_TAG_MANAGER: "GTM-TVHCJ39" // Producao
    }

}

export function getHeader(authorization:boolean,tokenUser: string){
        var headers:any = {headers:{
            "Content-Type": "application/json",
            "X-Frame-Options": "SAMEORIGIN",
            "X-Content-Type-Options": "nosniff",
            "Referrer-Policy": "origin-when-cross-origin",
            "Permissions-Policy": "*",
            "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
            "Content-Security-Policy": "default-src 'self'"
        }}
        
        if(authorization)
            headers = {headers:{
                "Content-Type": "application/json",
                "X-Frame-Options": "SAMEORIGIN",
                "X-Content-Type-Options": "nosniff",
                "Referrer-Policy": "origin-when-cross-origin",
                "Permissions-Policy": "*",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
                "Content-Security-Policy": "default-src 'self'",
                "Authorization": tokenUser
            }}
        
        return headers;
    
}
