import React, { useState, useContext, useEffect, useRef, KeyboardEvent } from 'react';
import Modal from "react-modal";
import { BsFillCheckCircleFill, BsSearch } from "react-icons/bs";
import { showError, showWait, dismissToast, showSucess } from '../../../../services/toast';
import { getProduct } from '../../../../services/getProduct';
import * as xlsx from "xlsx"
import { LoginContext } from '../../../../contexts/login_context';
import MenuSearch from '../../../../components/MenuSearch';
import DataTable, { TableColumn } from 'react-data-table-component';
import GtinResults from '../../../../components/GtinResults';
import Warning from '../../../../components/Warning';

import { processGTINData } from '../../../../services/gtin_process';
import { exportFile } from '../../../../services/exportFile';
import { exportFileFrontEndGTIN, exportFileTest } from '../../../../services/exportFileFrontEndGTIN';
import { IoMdClose } from 'react-icons/io';
import BootyPagination from '../../../../components/Pagination';
import { useSelector } from "../../../../native-state.dev";
import './style.css'


interface productData {
  image: string,
  gtin: string,
  description: string,
  icon: string,
  hasError: boolean,
  detail: any
}

interface DataRow {
  image: string;
  gtin: string;
  description: string;
  icon: string;
  hasError: boolean,
}


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  }
}

const SearchManyGTIN = () => {
  

  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);
  const no_image_url = '/jam.png';
  const ico_complete_product = '/vbg-horizontal.png';
  const { jwt } = useContext(LoginContext);
  const [name, setName] = useState(null);
  const [v, setV] = useState("");
  const [codes, setCodes] = useState<string[]>([]);
  const [exportCodes, setexportCodes] = useState<string[]>([]);
  const [startSearch, setStartSearch] = useState(false);
  const [code, setCode] = useState("");
  const unknown_text = 'Desconhecido';
  const max_gtins_per_search = 100;
  const [isChecked, setChecked] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const [view, setView] = useState(false);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [isOpenModalFaq, setIsOpenModalFaq] = useState(false);
  const [dataBackup, setdataBackup] = useState<any>(null)
  const [extension, setExtension] = useState('');

  const [modalDimensions, setModalDimensions] = useState({ w: '450px', h: '300px' });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const myRef: any = useRef();


  let productData = [{
    image: no_image_url,
    gtin: unknown_text,
    description: unknown_text,
    icon: '',
    hasError: false
  }];

  const [arrProducts, setArrProducts] = useState(productData);
  const [originalArr, setOriginalArr] = useState(productData);
  const [dataPrepared, setdataPrepared] = useState<any[]>([]);






  const help_link_gs1 = 'Por favor, verifique erros de digitação e GS1 Brasil se precisar de ajuda.';







  //Modal
  const open = () => {

    setExportButtonStatus(false);
    setStateModal(true)
    setExtension('')
  }

  const close = () => {
    setStateModal(false)
  }

  const openModalFaq = () => {
    setIsOpenModalFaq(true);
  }

  const closeModalFaq = () => {
    setIsOpenModalFaq(false);
  }

  const handleChangeExtension = (event: any) => {
    setExtension(event.target.value)
  }

  const downloadFile = (product: any, format: string, name: string): void => {

    let format_type: string;
    let extension: string;

    switch (format) {
      case 'xlsx':
        format_type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        extension = '.xlsx';
        break;

      case 'xls':
        format_type = 'application/vnd.ms-excel';
        extension = '.xls';
        break;

      case 'csv':
        format_type = 'text/csv';
        extension = '.csv';
        break;

      default:
        format_type = 'text/csv';
        extension = '.txt';
        break;
    }

    let file64base = `data:application/application/${format_type};base64, ${product}`;
    let a = document.createElement("a");
    a.href = file64base;
    a.download = `${name}.${extension}`;
    a.click();


  }

  const [exportButtonStatus, setExportButtonStatus] = useState(false);
  const exportFiles = async () => {
    setExportButtonStatus(true);

    let toastId = showWait('Aguarde, download em andamento.');

    if (extension !== '') {
      setState({searchDone:false});

      // const product: any = await exportFile(exportCodes, jwt, `${extension.toUpperCase()}`);

      // if (extension === 'xls') {
      //   downloadFile(product.data.xls, 'xlsx', 'planilha');
      // }
      // else if (extension === 'txt') {
      //   downloadFile(product.data.txt, 'txt', 'arquivo');
      // }
      // else {
      //   downloadFile(product.data.csv, 'csv', 'arquivo');
      // }
      exportFileTest(dataPrepared, extension);

      setStateModal(false);


    }
    else {
      window.alert("Por favor, escolha uma extensão");
    }
    setExportButtonStatus(false);
    dismissToast(toastId);

  }

  useEffect(() => {

    function handleResize() {
      setWindowDimensions(getWindowDimensions)
    }

    window.addEventListener("resize", handleResize);


    if (windowDimensions.width > 280 && windowDimensions.width < 420) {
      setModalDimensions({ w: '100%', h: '43vh' });
    }
    else if (windowDimensions.width < 576 && windowDimensions.width > 420) {
      setModalDimensions({ w: '80%', h: '30vh' });
    }
    else if (windowDimensions.width < 768 && windowDimensions.width > 576) {
      setModalDimensions({ w: '450px', h: '30vh' });
    }
    else {
      setModalDimensions({ w: '550px', h: '30vh' });
    }

    return () => window.removeEventListener("resize", handleResize);


  }, [windowDimensions.height, windowDimensions.width])


  //Upload file
  async function handleGetFile(file: any) {
    try {
      const extensionTXT = String(file.name).match(/txt/g)
      if (extensionTXT?.includes("txt")) {
        const archive = await file.text();
        setV(archive);
        return
      }

      const extensionCSV = String(file.name).match(/csv/g)
      if (extensionCSV?.includes("csv")) {
        const archive = await file.text();
        setV(archive);
        return
      }

      //xls or xlsx
      const reader = new FileReader();

      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        const archive = e.target?.result;
        const workbook = xlsx.read(archive, { type: "buffer" });
        const workSheetName = workbook.SheetNames[0];
        const workSheet = workbook.Sheets[workSheetName];

        const data = xlsx.utils.sheet_to_csv(workSheet).split("\n").filter((element: string | any[] | null) => {
          if (element != null && element.length > 0)
            return true;
        })

        setV(data.join(','));

      }
    }
    catch (error) {
      console.log(error)
    }
  }
  const checkDigit = (gtin: string) => {
    let position_number: number = 17;
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;

    

    if (gtin !== '') {
      //utilizado 14 porque é o número máximo de carateres que a GS1 trabalha
      if (gtin.length <= 14) {
        let position_value = [ 3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3]; //matriz base de cálculo (https://www.gs1.org/services/how-calculate-check-digit-manually)
        let gtin_total = 0;
        let gtin_positions = gtin.split("").slice(0, -1); 
        let add_zero = position_number - gtin.length + 1; 
        let multiple_10 = 0;

        if (add_zero < position_number) {
          for (let i = 0; i < add_zero; i++) {
            gtin_positions.unshift('0');
          }
        }

        for (let i = 0; i < position_number; i++) {
          if(position_value[i])
            gtin_total = gtin_total + (position_value[i] * parseInt(gtin_positions[i]));
        }

        if (gtin_total === Math.ceil((gtin_total) / 10) * 10) {
          multiple_10 = gtin_total;
        }
        else {
          multiple_10 = Math.ceil((gtin_total + 1) / 10) * 10;
        }

        let check_digit = multiple_10 - gtin_total

        if (check_digit !== parseInt(gtin.substring(gtin.length - 1))) {
          error = true;
          titleMessage = `Dígito verificador incorreto. Você quis dizer ${gtin.slice(0, -1)}${check_digit}?`;
          message = '';
        }

      }
      else {
        error = true;
        titleMessage = '';
        message = `O número do código de barras (GTIN) informado ${gtin} não pode ser maior que 14 dígitos.`;
      }
    }
    else {
      error = true;
      titleMessage = `Número do código de barras (GTIN) não foi encontrado.`;
      message = '';
    }

    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const validateGln = (gln: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
    setView(false);

    if (gln.length == 0) {
      error = true;
      titleMessage = 'Informe ao menos um GLN para consulta'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }
    else if (!/^[0-9]+$/.test(gln)) {
      error = true;
      titleMessage = 'O número GLN deve conter apenas caracteres numéricos.'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }
    else if (gln.length != 13) {
      error = true;
      titleMessage = 'O número que você digitou não tem comprimento válido para ser um GLN. Os GLNs devem ter 13 dígitos.'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }

    else {
      let verify_digit = checkDigit(gln);
      error = verify_digit.error;
      titleMessage = verify_digit.titleMessage
      message = verify_digit.message;
    }


    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const search = async (code: any) => {

    let productData: Array<productData> = [];

    let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');

    setView(false);

    const product: any = await getProduct(code, jwt);

    if (myRef && myRef.current)
      myRef.current.scrollIntoView();

    dismissToast(toastId);

    let data = null;
    if (product.data) {
      data = product.data;
      if(data.erro=="Erro na validação dos dados!"){
        
      }
      let auxDatePrepared: any[] = [];
      product.data.forEach((element: any) => {
        let item = exportFileFrontEndGTIN(element);
        auxDatePrepared.push(item);
      });
      setdataPrepared(auxDatePrepared);
      
      setState({searchDone:true});


    }

    let image: string;
    let gtin: string;
    let description: string;
    let icon: string;
    let error: boolean;


    if (data !== null) {

      setView(true);

      let has_error = [];
      let valid_gtins: any[] = [];

      //validações
      data.forEach((product: any) => {

        let cnp_messages = (product.mensagens && product.mensagens.cnp && product.mensagens.cnp !== undefined) ? product.mensagens.cnp[0] : null;
        let gepir_messages = (product.mensagens && product.mensagens.gepir && product.mensagens.gepir !== undefined && product.mensagens.gepir.length > 0) ? product.mensagens.gepir[0] : null;
        let vbg_messages = (product.mensagens && product.mensagens.vbg && product.mensagens.vbg !== undefined && product.mensagens.vbg.length > 0) ? product.mensagens.vbg[0] : null;
        let general_messages = (product.mensagens && product.mensagens.geral && product.mensagens.geral !== undefined && product.mensagens.geral.length > 0) ? product.mensagens.geral[0] : null;

        if (vbg_messages && !vbg_messages.erro && vbg_messages.visivel) {

          image = (product.VBG.productImageUrl !== undefined) ?
            (product.VBG.productImageUrl.length > 0) ? product.VBG.productImageUrl[0].value : no_image_url : no_image_url;
          gtin = product.GTIN;
          description = (product.VBG.productDescription) ? product.VBG.productDescription[0].value : (vbg_messages.mensagem) ? vbg_messages.mensagem : vbg_messages.titulo;
          icon = (product.VBG.gtinRecordStatus === "OK") ? ico_complete_product : '';
          error = false;
          valid_gtins.push(product.GTIN);
        }
        else if (cnp_messages && !cnp_messages.erro) {
          image = (product.CNP.product.referencedFileInformations && product.CNP.product.referencedFileInformations.length > 0)
            ? product.CNP.product.referencedFileInformations[0].uniformResourceIdentifier : no_image_url;
          gtin = product.GTIN;
          description = (product.CNP.product.tradeItemDescriptionInformationLang && product.CNP.product.tradeItemDescriptionInformationLang.length > 0)
            ? product.CNP.product.tradeItemDescriptionInformationLang[0].tradeItemDescription : (cnp_messages.mensagem) ? cnp_messages.mensagem : cnp_messages.titulo;
            icon = (product.VBG.gtinRecordStatus === "OK") ? ico_complete_product : '';
          error = false;
          valid_gtins.push(product.GTIN);
        }
        else if (!product.VBG && !product.CNP && !product.GEPIR && !general_messages) {
          image = no_image_url;
          gtin = product.GTIN;
          description = `O número do código de barras (GTIN) é inválido.`;
          icon = '';
          error = true;
          has_error.push(1);
        }
        else if (general_messages && general_messages.erro) {
          image = no_image_url;
          gtin = product.GTIN;
          if(general_messages.mensagem){
            let ttlerror = (general_messages.titulo!=null && general_messages.titulo != undefined)?general_messages.titulo:""
            let tmsgerror = (general_messages.mensagem!=null && general_messages.mensagem != undefined)?general_messages.mensagem:""
            description = ttlerror + " "+tmsgerror
          }
          
          icon = '';
          error = true;
          has_error.push(1);
        }
        else if (vbg_messages && vbg_messages.erro) {
          image = no_image_url;
          gtin = product.GTIN;
          description = (vbg_messages.mensagem) ? vbg_messages.mensagem : vbg_messages.titulo;
          icon = '';
          error = true;
          has_error.push(1);
        }
        else if (vbg_messages && !vbg_messages.erro && !product.VBG.isComplete) {
          image = no_image_url;
          gtin = product.GTIN;
          description = "Erro ao buscar este GTIN";
          icon = '';
          error = (vbg_messages.visivel) ? false : true;
          valid_gtins.push(product.GTIN);
          (vbg_messages.visivel) ? valid_gtins.push(product.GTIN) : has_error.push(1);
        }
        else if (product.VBG && product.VBG.brandName) {
          image = (product.VBG.productImageUrl !== undefined) ?
            (product.VBG.productImageUrl.length > 0) ? product.VBG.productImageUrl[0].value : no_image_url : no_image_url;
          gtin = product.GTIN;
          description = product.VBG.productDescription[0].value;
          icon = (product.VBG.gtinRecordStatus === "OK") ? ico_complete_product : '';
          error = false;
          valid_gtins.push(product.GTIN);
        }
        else if (product.CNP && product.CNP.product)//aqui vai cnp
        {
          image = (product.CNP.product.referencedFileInformations && product.CNP.product.referencedFileInformations.length > 0)
            ? product.CNP.product.referencedFileInformations[0].uniformResourceIdentifier : no_image_url;
          gtin = product.GTIN;
          description = product.CNP.product.tradeItemDescriptionInformationLang[0].tradeItemDescription;
          icon = (product.VBG.isComplete === true) ? ico_complete_product : '';
          error = false;
          valid_gtins.push(product.GTIN);
        }
        else if (product.VBG && product.VBG.code === 5 && product.CNP && product.CNP.status === 'Inválido' && product.GEPIR.returnCode === "1") {
          image = no_image_url;
          gtin = product.GTIN;
          description = product.CNP.message;
          icon = '';
          error = true;
          has_error.push(1);
        }
        else {
          image = no_image_url;
          gtin = product.GTIN;
          description = `O número do código de barras (GTIN) não foi encontrado.`;
          icon = '';
          error = true;
          has_error.push(1);
        }

        setexportCodes(valid_gtins);

        if (has_error.length > 0) {
          setHasSearchError(true);
        }
        else {
          setHasSearchError(false);
        }

        productData.push(
          {
            image: image,
            gtin: gtin,
            description: description,
            icon: icon,
            hasError: error,
            detail: processGTINData(product)
          });

      })

      setArrProducts(productData);
      setOriginalArr(productData);
      setStartSearch(true);

    }
    else {
      dismissToast(toastId);
      if (product.msg)
        showError(product.msg);
      else
        showError(`Erro ao executar pesquisa.`);
    }

  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    send()
  }
  const handleKeyboardEvent = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      send()
    }
  };
  async function send() {


    if (name) {
      await search(codes);
    }
    else {
      if (v === '') {
        showError(`Por favor, informe ao menos um GTIN para consulta.`);
        return
      }
      if (v.split(",").length > max_gtins_per_search) {
        showError(`Somente é possível pesquisar até ${max_gtins_per_search} números. Foi requisitado ${v.split(",").length} números para consulta.`);
        return
      }

      await search(v.replace(/\s+/g, "").split(","));
    }
  }


  const handleChange = () => {
    setChecked(!isChecked);
  };


  useEffect(
    () => {
      let retorno = [];
      if (isChecked) {
        retorno = originalArr.filter((d: { icon: string; }) => (d.icon !== ''));
      }
      else {
        retorno = originalArr.filter((d: any) => (true));
      }

      if (code !== '') {
        retorno = retorno.filter((d: { gtin: string | any[]; }) => (d.gtin.indexOf(code) >= 0));
      }

      setArrProducts(retorno);
    }, [code, isChecked, originalArr]);


  const findGtin = (code: string | undefined) => {
    if (code !== undefined) {
      setCode(code)
    }
  }


  const showDetails = (gtin: string) => {

    let product: any = originalArr.find((element: any) => {
      if (element.gtin === gtin)
        return true;
    })

    setDetails(product.detail);

  }


  const GtinLink = (row: any) => <div
    onClick={() => { showDetails(row.gtin) }}
    style={{
      textDecoration: "underline",
      color: "#00799E",
      lineHeight: 2,
      cursor: "pointer"
    }}
  >
    {row.gtin}
  </div>;


  const columns: TableColumn<DataRow>[] = [
    {
      name: '',
      selector: (row: { image: any; }) => row.image,
      cell: (row: { image: string; description: any; }) => row.image !== '' ? <div style={{ overflow: 'hidden' }}><img src={row.image} height={55} alt={row.description} /></div> : '',
      maxWidth: '80px',
      wrap: true,
      center: true
    },
    {
      name: 'GTIN',
      selector: (row: { gtin: any; }) => row.gtin,
      sortable: true,
      wrap: true,
      cell: (row: any) => <GtinLink {...row} />,
      width: '150px'
    },
    {
      name: 'Descrição do Produto',
      selector: (row: { description: any; }) => row.description,
      maxWidth: '810px',
      wrap: true,
      sortable: true,
    },
    {
      name: '',
      selector: (row: { icon: any; }) => row.icon,
      wrap: true,
      cell: (row: { icon: string; description: any; }) => row.icon !== '' ? <img src={row.icon} width={50} alt={row.description} style={{ marginRight: '10px' }} /> : '',
      width: '50px',
      center: true
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: { hasError: boolean; }) => row.hasError === true,
      style: {
        backgroundColor: '#FFDFDF',
        color: '#BA1B23',
        '&:hover': {
          backgroundColor: '#BA1B23',
          color: '#FFDFDF'
        },
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '62px', // override the row height
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c"
      },
    },
    headCells: {
      style: {
        paddingLeft: '6px', // override the cell padding for head cells
        paddingRight: '6px',
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c"
      },
    },
    cells: {
      style: {
        paddingLeft: '6px', // override the cell padding for data cells
        paddingRight: '6px',
      },
    },
    pagination: {
      style: {
        color: "#002c6c",
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: '0.94rem',
        minHeight: '56px',
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Mostrar',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };




  return (
    <>


      {!details &&
        <>


          <div className='bg-color-ui-1 '>

            <div className='container customWithManyOtherGtin'>

              <div className='row bg-color-white px-4 rounded-lg' style={{margin:'0px'}}>

                <div className='col-12 col-md-12 pr-5 mb-4 customPaddingGLN' style={{ marginTop: "-10px"}}>



                  <div className='row  px-2' style={{ marginBottom: "20px", maxWidth:'1000px' }}>
                    <textarea
                      onKeyDown={handleKeyboardEvent}
                      onChange={({ target }) => { setName(null); setV(target.value) }}
                      value={v}
                      onDrop={(e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: FileList; clearData: () => void; }; }) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                          handleGetFile(e.dataTransfer.files[0])
                          e.dataTransfer.clearData()
                        }
                      }}
                      className="area"
                      placeholder="Pesquise até 100 código de barras (GTIN) por vez, digite aqui, separando por vírgulas, ou arraste e solte o arquivo (formatos .xlsx, .txt, .csv) para adicionar os GTINS"
                      id='textareaId'
                    ></textarea>
                    <span style={{ fontSize: "0.94rem" }}>
                    Exemplo:
                    <a
                      onClick={() => setV("9506000140445")}
                      className="call_link"
                    >
                      9506000140445
                    </a>
                  </span>
                  </div>
                  
                  <div className='row pl-3' style={{ marginTop: "10px" }}>
                    <p>Upload de arquivo:</p>
                  </div>
                  <div className="buttonRowSearcManyCustom">
                    <label className='chooseFileCustom'>
                      {name || "Escolher arquivo"}
                      <input
                        onChange={(e: { target: { files: FileList | null; value: string; }; }) => {
                          if (e && e.target && e.target.files && e.target.files.length > 0) {
                            handleGetFile(e.target.files[0]);
                            e.target.value = '';
                          }
                        }}
                        style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                        accept=".csv,.txt,.xls, .xlsx"
                        type="file"
                        name=""
                        id=""
                      />
                    </label>

                    <div className='btnSearchMultipleCustom'>
                      <button
                        className='btn btn-primary buttonEspecialGLNCustom'
                        onClick={send}
                      >
                        Buscar
                      </button>

                    </div>

                  </div>
                </div>



              </div>

            </div>

          </div>


          <div ref={myRef}></div>

          {startSearch === true && (

            <>
              {view &&

                <div className='bg-color-ui-1  wp-home'>

                  <div className='container'>

                    {hasSearchError &&

                      <div className='row bg-color-white px-4 pt-4'>
                        <Warning
                          type='error'
                          showpick={false}
                          title={'Alguns resultados da busca apresentam erros.'}
                          text={help_link_gs1}
                        ></Warning>
                      </div>

                    }

                    <div className='row bg-color-white py-5 px-4 rounded-lg'>

                      <div className='row' style={{ width: "100%" }}>

                        <div className='col-12 col-md-7 col-lg-9'>
                          <h2 style={{ marginTop: 0, marginBottom: '0.5rem' }}>Resultado da Pesquisa Múltipla</h2>
                          <p className='little_text'>Clique no GTIN para visualizar mais detalhes do produto.</p>
                        </div>

                        <div className='col-12 col-md-5 col-lg-3'>

                          <div className='buttons_headerinfos'>

                            <button onClick={() => window.print()}>
                              Imprimir
                            </button>

                            <button name='buttonExport' onClick={() => open()}>
                              Exportar
                            </button>

                          </div>
                        </div>

                      </div>

                    </div>

                    <div className='row bg-color-white pb-2 px-0'>

                      <div className='col-12 col-md-6 col-lg-4 mb-3 pl-1'>

                        <div className='inputSearch'>
                          <div className="searchbox">
                            <label className="container_my_input">

                              <input
                                placeholder="Buscar"
                                onChange={({ target }) => findGtin(target.value)}
                                value={code}
                                className="especial_input"
                                maxLength={14}
                              />
                            </label>
                            <button
                              onClick={() => findGtin(undefined)}
                              className="btnfind"
                            >
                              <img src="./../../search.svg" alt="busca" />
                            </button>
                          </div>
                        </div>


                      </div>

                      <div className='col-12 col-md-6 col-lg-8 pl-sm-4 mb-3 pt-1'>

                        <div className='completeProducts little_text'>
                          <label>
                            <input type="checkbox" checked={isChecked} onChange={handleChange} value="completos" />
                            Mostrar somente produtos com dados completos
                          </label>
                        </div>

                      </div>

                    </div>

                    <div className='row px-0 bg-color-white'>
                      <div className='col-12 px-0' style={{ borderTop: 'solid 1px #d8d8d8' }} >
                        <DataTable
                          columns={columns}
                          data={arrProducts}
                          pagination
                          responsive
                          noDataComponent="Sem registros para exibir"
                          highlightOnHover
                          conditionalRowStyles={conditionalRowStyles}
                          customStyles={customStyles}
                          paginationComponent={BootyPagination}
                        />
                      </div>
                    </div>

                  </div>

                </div>

              }

            </>

          )}

        </>
      }


      {details &&
        <div className='bg-color-ui-1 pt-5 pb-5 wp-home mt-4'>
          <div className='container'>
            <div style={{ paddingBottom: 10, fontSize: "14pt", color: "#0c9ae3", cursor: "pointer" }} onClick={() => setDetails(null)}>&lt; Voltar para o resultado de busca múltipla</div>

            
            <div className='row bg-color-white py-2 px-4 rounded-lg' style={{ width: '100%' }}>
              <div className='col-12 px-0'>

                <GtinResults data={details} code={[details.gtin]} />

              </div>
            </div>
          </div>
        </div>

      }


      <Modal
        isOpen={stateModal}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000,
          },
          content: {
            width: "370px",
            height: "330px",
            marginTop: "10%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => close()}
            style={{ position: "absolute", right: "7px", top: "7px" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
          >
            <span
              style={{
                color: "#002C6C",
                fontSize: "1.375rem",
                marginBottom: "-25px",
              }}
            >
              Exportar Arquivo
            </span>
            <span
              style={{
                color: "#002C6C",
                fontSize: "13px",
                marginBottom: "-5px",
                fontWeight: "400"
              }}>
              Escolha o formato de arquivo para exportarf
            </span>
            <span
              style={{
                fontSize: "13px",
                marginBottom: "-20px"
              }}
            >
              Formatos de arquivos
            </span>
            <span
              style={{
                fontSize: "14px",
                display: "felx",
                flexDirection: "column"
              }}
            >
              <div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="txt"
                    name="extension"
                    checked={extension === 'txt'}
                    onChange={handleChangeExtension}

                  /> &nbsp; .txt
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="xls"
                    name="extension"
                    checked={extension === 'xls'}
                    onChange={handleChangeExtension}
                  /> &nbsp; .xls
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "5px"
                }}>
                  <input
                    type="radio"
                    value="csv"
                    name="extension"
                    checked={extension === 'csv'}
                    onChange={handleChangeExtension}
                  /> &nbsp; .csv
                </div>
              </div>
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >

              <button disabled={exportButtonStatus} onClick={() => exportFiles()} className="btn btn-primary btn-sm">
                Exportar
              </button>
              <button disabled={exportButtonStatus} onClick={() => close()} className="btn btn-outline-primary btn-sm">
                Cancelar
              </button>

            </div>
          </div>
        </div>

      </Modal>

      <Modal
        isOpen={isOpenModalFaq}
        onRequestClose={closeModalFaq}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensions.w,
            height: modalDimensions.h,
            marginTop: "10%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",

          },

        }}
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => closeModalFaq()}
            style={{ position: "absolute", right: "7px", top: "7px" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
          >
            <span
              style={{
                color: "#002C6C",
                fontSize: "1.3rem",
                marginBottom: "0px",
              }}
            >
              Qual a quantidade de consultas que cada empresa tem direito?
            </span>


            <div
              style={{
                width: "100%",
                display: "block",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: 1.3
              }}
            >

              Cada empresa associada a GS1 Brasil, hoje, tem direito a consultar 30 códigos de barras (GTIN) por dia. Caso sua empresa precise de um volume maior de consultas, procure a
              &nbsp;<a href="mailto:atendimento@gs1br.org" target="_blank" rel="noreferrer">GS1 Brasil</a>. Caso já tenha solicitado, consulte na área de administrador.

            </div>
          </div>
        </div>

      </Modal>
    </>
  );
};

export default SearchManyGTIN;



