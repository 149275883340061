import { title } from 'process';
import React, { useContext, useRef, useState } from 'react';
import { BsFillCheckCircleFill, BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';


import GtinResults from '../../../../components/GtinResults';
import ExportDetail from '../../../../components/ExportDetail';
import Warning from '../../../../components/Warning';
import { LoginContext } from '../../../../contexts/login_context';
import { dismissToast, showError, showWait } from '../../../../services/toast';
import { getCompany } from '../../../../services/getCompany';
import { createErrorMsg, processGLNData } from '../../../../services/gln_process';
import GlnResults from '../../../../components/GlnResults';
import { exportFileFrontEndGTIN } from '../../../../services/exportFileFrontEndGTIN';
import NewWarning from '../../../../components/newWarning';
import { exportFileFrontEndGLN, exportFileFrontEndGLNError } from '../../../../services/exportFileFrontEndGLN';
import { useSelector } from '../../../../native-state.dev';



const SearchOneGLN = () => {
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);
  const no_image_url = '/jam.png';
  const [view, setView] = useState({ error: false, content: false });
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { jwt } = useContext(LoginContext);
  const [code, setCode] = useState("");
  const unknown_text = 'Não cadastrado';
  const [isFocused, setFocused] = useState(false)
  const myRef: any = useRef();

  let navigate = useNavigate();
  let location = useLocation();


  let errorMsg = "";
  let errorTitles = "";
  let showpicMsg = false

  const [data, setData] = useState<any>();
  const [showMSG, setShowMSG] = useState(false);
  const [dataPrepared, setdataPrepared] = useState<any>();

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send()
  }
  async function send() {
    searchCode();

  }
  const checkDigit = (gtin: string) => {
    let position_number: number = 17;
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;

    

    if (gtin !== '') {
      //utilizado 14 porque é o número máximo de carateres que a GS1 trabalha
      if (gtin.length <= 14) {
        let position_value = [ 3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3]; //matriz base de cálculo (https://www.gs1.org/services/how-calculate-check-digit-manually)
        let gtin_total = 0;
        let gtin_positions = gtin.split("").slice(0, -1); 
        let add_zero = position_number - gtin.length + 1; 
        let multiple_10 = 0;

        if (add_zero < position_number) {
          for (let i = 0; i < add_zero; i++) {
            gtin_positions.unshift('0');
          }
        }

        for (let i = 0; i < position_number; i++) {
          if(position_value[i])
            gtin_total = gtin_total + (position_value[i] * parseInt(gtin_positions[i]));
        }

        if (gtin_total === Math.ceil((gtin_total) / 10) * 10) {
          multiple_10 = gtin_total;
        }
        else {
          multiple_10 = Math.ceil((gtin_total + 1) / 10) * 10;
        }

        let check_digit = multiple_10 - gtin_total

        if (check_digit !== parseInt(gtin.substring(gtin.length - 1))) {
          error = true;
          titleMessage = `Dígito verificador incorreto. Você quis dizer ${gtin.slice(0, -1)}${check_digit}?`;
          message = '';
        }

      }
      else {
        error = true;
        titleMessage = '';
        message = `O número do código de barras (GTIN) informado ${gtin} não pode ser maior que 14 dígitos.`;
      }
    }
    else {
      error = true;
      titleMessage = `Número do código de barras (GTIN) não foi encontrado.`;
      message = '';
    }

    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const validateGln = (gln: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
    setView({ error: false, content: false });

    if (gln.length == 0) {
      error = true;
      titleMessage = 'Informe ao menos um GLN para consulta'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }
    else if (!/^[0-9]+$/.test(gln)) {
      error = true;
      titleMessage = 'O número GLN deve conter apenas caracteres numéricos.'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }
    else if (gln.length != 13) {
      error = true;
      titleMessage = 'O número que você digitou não tem comprimento válido para ser um GLN. Os GLNs devem ter 13 dígitos.'
      message = `Verifique se há erros de digitação e entre em contato com a GS1 se precisar de ajuda.`;
    }

    else {
      let verify_digit = checkDigit(gln);
      error = verify_digit.error;
      titleMessage = verify_digit.titleMessage
      message = verify_digit.message;
    }


    return {
      error: error,
      titleMessage: titleMessage,
      message: message
    }
  }


  const searchCode = async () => {

    let valid = validateGln(code);

    if (!valid.error) {
      let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');
      setIsDisabled(true);

      const response: any = await getCompany([code], jwt);
      dismissToast(toastId);
      setIsDisabled(false);
      if (response.status === 200) {


        if (response.data != null && response.data.length > 0) {
          setView({ error: false, content: true });
          
          setState({searchDone:true});


          if (myRef && myRef.current)
            myRef.current.scrollIntoView();
            let errorMsg = {}
          if(!!response.data[0].validationErrors){
            errorMsg = createErrorMsg(response.data[0])
            let auxData:any={}
            auxData.retornoMsg = errorMsg;
            setData(auxData)
            setdataPrepared(exportFileFrontEndGLNError({gln:code}));

          }else{
            let item = processGLNData(response.data[0])
            setData(item);
            setdataPrepared(exportFileFrontEndGLN(item));

            if(response.data.length>1){
              errorMsg =  createErrorMsg(response.data[1])
              data.retornoMsg = errorMsg;
              setData(data)
              // setdataPrepared(data);

            }
            

            
          }



          console.log(data);
        }
        else {
          setView({ error: true, content: false });
          setErrorTitle(`O número do GLN não foi encontrado.`);
        }

      }
      else {

        if (response.code === 401) {
          navigate("/" + location.search);
        }
        showError(response.msg);

        setView({ error: false, content: false });
      }
    }
    else {
      setView({ error: true, content: false });
      setErrorTitle(valid.titleMessage);
      setErrorMessage(valid.message);
    }


  }


  return (

    <>


      <div className='bg-color-ui-1  ' >

        <div className='container'>

          <div className='row customWidth bg-color-white  px-4 rounded-lg' >

            <div className='col-12 col-md-12 mb-5' >
              <form className='customForm' onSubmit={onFormSubmit}>
                  <div className='custom-container-input' style={{display:'flex'}}>
                        <div className='custom-border5GLN'
                        >
                            <label
                            
                            className='container_my_input_custom custom-height-container-input'>
                            <BsSearch />
                              <input
                              id='inputIdGLN'
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              placeholder="Digite um número de código de barra (GLN)"
                              onChange={({ target }) => setCode(target.value)}
                              value={code}
                              className="especial_input"
                              style={{outline:'none', border: 'none'}}
                              disabled={isDisabled}
                              />

                            </label>
                        </div>
                        
                        <div>
                          <button
                              type="submit"
                              className="custom-button-especial"
                              disabled={isDisabled}
                          >
                              Buscar
                          </button>
                        </div>
                    </div>
                </form>    
                <div style={{ marginTop: "5px" }}>
                            <span className="customSpan">
                              Exemplo:{" "}
                              <a href='javascript:;'>
                                <span
                                  onClick={() => setCode("9506000140445")}
                                  className="link"
                                >
                                    9506000140445
                                </span>
                              </a>
                            </span>
                </div>
          </div>


            <div ref={myRef}></div>



          </div>

        </div>

      </div>

      <div className='col-12' style={{ width: '100%', overflow: 'hidden' , padding: '0'}}>

        {view.error && (

          <div className='row'>

            <NewWarning
              type='error'
              showpick={false}
              title={errorTitle}
              text={errorMessage}
            ></NewWarning>

          </div>

        )}

        {view.content && (

          <>
            
            <GlnResults data={data} />


          </>

        )}

      </div>


      {view.content && (<ExportDetail dataPrepared={dataPrepared} itemType={'GLN'}  />)}

    </>

  );


};

export default SearchOneGLN;



